<template>
  <section v-if="jobs">
    <h3> 
      Reports
    </h3>
    <br>

    <b-card bg-variant="light">
      <b-row>
        <b-col cols="2"><strong>Started</strong></b-col>
        <b-col cols="3"><strong>Type</strong></b-col>
        <b-col cols="2"><strong>Status</strong></b-col>
        <b-col cols="5"><strong>Download</strong></b-col>
      </b-row>
      <div v-for="(job,index) in jobs" :key="index">
        <br>
        <b-row>
          <b-col cols="2">{{ localTime(job.jobTimestamp) }}</b-col>
          <b-col cols="3">{{ job.jobType }}</b-col>
          <b-col cols="2">{{ job.status.status }}</b-col>
          <b-col v-if=" job.status.status === 'pending'" cols="5"></b-col>
          <b-col v-if=" job.status.status === 'failed'" cols="5">{{ job.status.message }}</b-col>
          <b-col v-if=" job.status.status === 'finished'" cols="5">
            <a href="#" @click.stop="getJobDownloadLink(job)">{{ job.fileForDownload }}</a>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </section>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {

  components: {
    BCard,
    BRow,
    BCol,
  },
 
  data () {
    return {
      jobs: null
    }
  },
  computed: { 

  },
  watch: {
 
  },
  mounted () {
    this.getJobList()
  },
  methods: {
    localTime (jobTimestamp) {
      //convert "2021-10-03-15-52-40-865375-10" to iso 2021-10-03T15:52:40Z
      let nodes = jobTimestamp.split('-')
      const anIso =  nodes[0].concat('-', nodes[1], '-', nodes[2], 'T', nodes[3], ':', nodes[4], ':', nodes[5], 'Z')
      const d = new Date(anIso)
      const dateAndTime =  d.toLocaleString().split(',')
      const dateString =  dateAndTime[0]
      nodes = dateString.split('/')
      return nodes[0].concat('/', nodes[1].concat(', '), dateAndTime[1])
    },
    getJobList () {
      publicationApi.getJobList(this.$route.params.issn, this, null)
    },
    backFromGetJobList (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.reportResults = 'Error'
      } else {
        console.log('ssss', serverData)
        this.jobs = serverData
      }
    },
    getJobDownloadLink (job) {
      fileMgrApi.getJobDownloadLink(this.$route.params.issn, job, this, null)
    },

    backFromGetJobDownloadLink (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        document.body.appendChild(fileLink)
        fileLink.click() 
      } 
    },
  },
}
</script>
